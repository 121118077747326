// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
            @media screen and (max-width: $size-xs-max) {
                bottom: 190px;
            }
        }
        &_caption {
            position: absolute;
            top: 45%;
            left: 55%;
            width: 100%;
            transform: translateX(-50%);
            z-index: $z-index-slide-caption;
            
            h2 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-white;
                letter-spacing: 0.72px;
            }
            a.btn-slideshow {
                background: #080808;
                border: 1px solid $color-1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 235px;
                height: 80px;
                border-radius: 6px;
                margin-top: 25px;
                transition: 0.3s;
                h5 {
                    font-weight: 700;
                    font-size: $font-size-18;
                    color: $color-1;
                    letter-spacing: 1.8px;
                    transition: 0.3s;
                }
                &:hover {
                    background: $color-1;
                    transition: 0.3s;
                    h5 {
                        color: #080808;
                        transition: 0.3s;
                    }
                }
            }
            @media screen and (max-width: $size-md-max) {
                top: 35%;
                @media screen and (max-width: 1024px) {
                    top: 30%;
                    h2 {
                        font-size: 2.5rem;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        top: 52%;
                        h2 {
                            font-size: 2rem;
                        }
                        @media screen and (max-width: 375px) {
                            top: 49%;
                            h2 {
                                font-size: 1.85rem;
                            }
                            @media screen and (max-width: 360px) {
                                h2 {
                                    font-size: 1.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section-btn {
            position: absolute;
            bottom: 1px;
            right: 0;
            z-index: 10;
            margin-right: 5%;
            a.link-slideshow {
                background: $color-1;
                padding: 35px;
                width: 230px;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-18;
                    color: #100000;
                    line-height: 1.3;
                    letter-spacing: 0.72px;
                    text-transform: uppercase;
                }
            }
            @media screen and (max-width: 1024px) {
                a.link-slideshow {
                    padding: 30px;
                    width: 225px;
                }
                @media screen and (max-width: $size-sm-max) {
                    a.link-slideshow {
                        padding: 25px;
                        width: 190px;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        margin-right: 0;
                        flex-direction: column;
                        position: initial;
                        margin-top: -1px;
                        a.link-slideshow {
                            padding: 30px;
                            width: 100%;
                            &.mr2 {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-image-text {
    padding: 100px 0;
    position: relative;
    .image-side {
        width: 55%;
        margin-right: 50px;
    }
    .text-side {
        width: 50%;
        padding-right: 15%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #2D2D2D;
            letter-spacing: 1.2px;
            text-transform: initial;
        }
        p {
            padding: 25px 0;
        }
        h4 {
            font-weight: 700;
            font-size: $font-size-28;
            color: #2D2D2D;
            letter-spacing: 1.04px;
            font-style: italic;
        }
    }
    &::before {
        content: '';
        width: 69%;
        height: 90%;
        background: #0808080B;
        position: absolute;
        right: 0;
        z-index: -1;
    }
    
    @media screen and (max-width: 1460px) {
        .text-side {
            padding-right: 4%;
        }   
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            padding: 75px 5%;
            .image-side {
                width: 75%;
                margin: 0 0 35px 0;
            }
            .text-side {
                padding-right: 0;
                width: 100%;
            }   
            &::before {
                width: 100%;
                height: 60%;
                bottom: 0;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    &::before {
                        height: 67%;
                    }
                }
            }
        }
    }
}

#section-project {
    padding: 75px 5%;
    .section-top {
        padding-bottom: 75px;
        .section-stats {
            width: 30%;
            .left {
                padding-right: 10px;
                h2 {
                    font-weight: bold;
                    font-size: $font-size-40;
                    color: #080808;
                }
            }
            .right {
                h4 {
                    font-weight: 700;
                    font-size: $font-size-18;
                    color: #2B2B2B;
                    text-transform: uppercase;
                }
            }
        }
        .section-text {
            width: 85%;
            h3 {
                font-weight: normal;
                font-size: $font-size-26;
                color: #080808;
                text-transform: initial;
                line-height: 1.3;
            }
        }
    }
    .section-bottom {
        .service {
            .img-responsive {
                margin: auto;
            }
            h5 {
                font-weight: 700;
                font-size: $font-size-20;
                color: #2B2B2B;
                text-align: center;
                padding-top: 20px;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .section-top {
            .section-stats {
                width: 40%;
            }
        }
        @media screen and (max-width: 1480px) {
            .section-bottom {
                flex-wrap: wrap;
                justify-content: initial;
                .service {
                    width: 25%;
                    padding: 25px;
                }
            }
            @media screen and (max-width: 1425px) {
                .section-top {
                    .section-stats {
                        width: 45%;
                    }
                    .section-text {
                        h3 {
                            font-size: 1.15rem;
                        }
                    }
                }
                @media screen and (max-width: 1160px) {
                    .section-top {
                        flex-wrap: wrap;
                        padding-bottom: 50px;
                        .section-stats {
                            width: 33.3333%;
                            justify-content: center;
                        }
                        .section-text {
                            width: 100%;
                            padding-top: 45px;
                            text-align: center;
                            h3 {
                                font-size: 1.3rem;
                            }
                        }
                    }
                    .section-bottom {
                        .service {
                            h5 br {
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .section-bottom {
                            .service {
                                h5 {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .section-bottom {
                                .service {
                                    width: 33%;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                padding: 35px 5%;
                                .section-top {
                                    .section-stats {
                                        width: 100%;
                                        padding-bottom: 25px;
                                    }
                                    .section-text {
                                        padding-top: 10px;
                                    }
                                }
                                .section-bottom {
                                    .service {
                                        width: 50%;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
}

#border {
    background: #2D2D2D;
    height: 1px;
    margin: 10px 5%;
}

#section-tarifs {
    padding: 50px 5%;
    .title {
        text-align: center;
        padding-bottom: 75px;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #100000;
            padding-bottom: 10px;
        }
        h5 {
            font-weight: 700;
            font-size: $font-size-20;
            color: #202124;
        }
    }
    .section-blocs {
        .bloc {
            width: 25%;
            .section-title {
                background: #101000;
                padding: 25px;
                margin-bottom: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                h2 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: $color-1;
                    text-transform: uppercase;
                }
            }
            .section-text {
                background: #E4E4E4;
                padding: 75px 35px 45px 35px;
                h4 {
                    font-weight: 700;
                    font-size: $font-size-20;
                    color: #080808;
                }
                a.link-tarif {
                    background: #080808;
                    border: 1px solid $color-1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 245px;
                    height: 80px;
                    border-radius: 5px;
                    margin-top: 50px;
                    transition: 0.3s;
                    h5 {
                        font-weight: 600;
                        font-size: $font-size-18;
                        color: $color-1;
                        letter-spacing: 1.8px;
                        transition: 0.3s;
                    }
                    &:hover {
                        background: $color-1;
                        transition: 0.3s;
                        h5 {
                            color: #080808;
                            transition: 0.3s;
                        } 
                    }
                    &.long {
                        width: 315px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1695px) {
        .section-blocs {
            .bloc {
                width: 28%;
            }
        }
        @media screen and (max-width: 1550px) {
            .section-blocs {
                .bloc {
                    width: 35%;
                }
            }
            @media screen and (max-width: 1280px) {
                .section-blocs {
                    .bloc {
                        .section-text {
                            a.link-tarif {
                                width: 200px;
                            }
                            a.link-tarif.long {
                                width: 260px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1165px) {
                    .section-blocs {
                        flex-wrap: wrap;
                        .bloc {
                            width: 45%;
                            padding-bottom: 0.75rem;
                        }
                        .bloc.px3 {
                            padding-right: 0;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .section-blocs {
                            .bloc {
                                width: 50%;
                                .section-text {
                                    a.link-tarif {
                                        width: 175px;
                                        height: 70px;
                                    }
                                    a.link-tarif.long {
                                        width: 225px;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .title {
                                padding-bottom: 50px;
                            }
                            .section-blocs {
                                .bloc {
                                    width: 100%;
                                }
                                .bloc.px3 {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-logo-btn {
    padding: 75px 5%;
    a.btn-link {
        background: #080808;
        border: 1px solid $color-1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 470px;
        height: 80px;
        border-radius: 5px;
        margin: 75px auto 0 auto;
        transition: 0.3s;
        h5 {
            font-weight: 600;
            font-size: $font-size-18;
            color: $color-1;
            letter-spacing: 1.8px;
            transition: 0.3s;
        }
        &:hover {
            background: $color-1;
            transition: 0.3s;
            h5 {
                color: #080808;
                transition: 0.3s;
            } 
        }
    }
    @media screen and (max-width: $size-sm-max) {
        a.btn-link {
            width: 390px;
        }
        @media screen and (max-width: $size-xs-max) {
            .section-logo {
                flex-direction: column;
                .logo.mr8 {
                    margin: 0 0 2rem 0;
                }
            }
            a.btn-link {
                width: 320px;
            }
        }
    }
}

#section-text-video {
    padding: 100px 5% 75px 5%;
    position: relative;
    .text-side {
        margin-right: 50px;
        h3 {
            font-weight: 700;
            font-size: $font-size-20;
            color: #202124;
            line-height: 1.3;
        }
    }
    &::before {
        content: '';
        width: 57%;
        height: 91%;
        background: #EAE7DA91;
        position: absolute;
        left: 0;
        z-index: -1;
    }
    @media screen and (max-width: 1700px) {
        .video-side {
            iframe {
                width: 1000px;
                height: 625px;
            }
        }
        &::before {
            height: 760px;
        }
        @media screen and (max-width: 1550px) {
            .video-side {
                width: 60%;
                iframe {
                    width: 840px;
                    height: 490px;
                }
            }
            &::before {
                height: 615px;
            }
            @media screen and (max-width: 1475px) {
                .video-side {
                    width: 70%;
                }
                &::before {
                    width: 1000px;
                }
                @media screen and (max-width: $size-md-max) {
                    .video-side {
                        iframe {
                            width: 745px;
                            height: 435px;
                        }
                    }
                    &::before {
                        width: 840px;
                        height: 555px;
                    }
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                        padding: 75px 5%;
                        .text-side {
                            margin: 0 0 35px 0;
                            text-align: center;
                        }

                        &::before {
                            display: none;
                        }
                        @media screen and (max-width: 1024px) {
                            .video-side {
                                width: 78%;
                            }
                            @media screen and (max-width: $size-sm-max) {
                                .video-side {
                                    width: 100%;
                                    iframe {
                                        width: 100%;
                                        height: 390px;
                                    }
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    padding: 50px 5%;
                                    .video-side {
                                        iframe {
                                            height: 265px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    padding: 75px 5%;
    a.link-burst {
        background: #080808;
        border: 1px solid $color-1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 235px;
        height: 80px;
        border-radius: 5px;
        margin-top: 35px;
        transition: 0.3s;
        h5 {
            font-weight: 600;
            font-size: $font-size-18;
            color: $color-1;
            letter-spacing: 1.8px;
            transition: 0.3s;
        }
        &:hover {
            background: $color-1;
            transition: 0.3s;
            h5 {
                color: #080808;
                transition: 0.3s;
            } 
        }
    }
    .left-side {
        width: 50%;
        margin-right: 15px;
        
    }
    .right-side {
        width: 50%;
    }
    .text {
        padding: 20px 15px 0 15px;
        h3 {
            font-weight: 700;
            font-size: $font-size-20;
            color: #202124;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: 1110px) {
        flex-direction: column;
        .left-side {
            width: 70%;
            margin: 0 auto 35px auto;
        }
        .right-side {
            width: 70%;
            margin: auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side, .right-side {
                width: 85%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side, .right-side {
                    width: 100%;
                    .text h3 br {
                        display: none;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* TOITURE RÉSIDENTIELLE
*******************************************************************************/

#section-intro-up.reverse {
    padding: 0 5% 75px 5%;
    margin-top: -250px;
    position: relative;
    .left-side {
        width: 55%;
        background: url("../images/residentielle_section01_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    .right-side {
        width: 45%;
        margin: 0 15px;
        background: #100000;
        padding: 65px 45px 35px 45px;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #E4E4E4;
            padding-bottom: 15px;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: $color-1;
            text-transform: uppercase;
            line-height: 1.3;
            padding-bottom: 35px;
        }
        p {
            color: #E4E4E4;
        }
        a.btn-link {
            background: #080808;
            border: 1px solid $color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 210px;
            height: 80px;
            border-radius: 5px;
            margin-top: 35px;
            transition: 0.3s;
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-1;
                letter-spacing: 1.8px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                h5 {
                    color: #080808;
                    transition: 0.3s;
                } 
            }
        }
    }
    @media screen and (max-width: 1170px) {
        flex-direction: column-reverse;
        margin-top: -110px;
        .right-side {
            width: 100%;
            margin: 0 0 15px 0;
        }
        .left-side {
            width: 100%;
        }
        @media screen and (max-width: $size-sm-max) {
            margin-top: -100px;
            @media screen and (max-width: $size-xs-max) {
                margin-top: -145px;
                .left-side {
                    background: url("../images/residentielle_section01_img01_m.jpg") no-repeat;
                    background-size: cover;
                    background-position: center;
                }
                .right-side {
                    padding: 60px 25px 35px 25px;
                }
            }
        }
    } 
}

#section-img-img {
    padding: 25px 5% 35px 5%;
    .left-side {
        width: 50%;
        margin-right: 25px;
    }
    .right-side {
        width: 50%;
    }
    .section-text {
        padding-top: 20px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 15px;
        }
        p {
            width: 90%;
        }
        .section-list {
            padding-top: 10px;
            .left {
                width: 45%;
                &.second {
                    width: 51%;
                    @media screen and (max-width: $size-xs-max) {
                        width: 100%;
                    }
                }
            }
            .bloc {
                .text {
                    width: 100%;
                    h5 {
                        font-weight: 600;
                        font-size: $font-size-18;
                        color: #202124;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .section-text {
            .section-list {
                .left {
                    width: 49%;
                }
            }
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .left-side {
                width: 75%;
                margin: 0 auto 35px auto;
            }
            .right-side {
                width: 75%;
                margin: 0 auto;
            }
            .section-text {
                p {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .left-side, .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-text {
                        .section-list {
                            flex-direction: column;
                            .left {
                                width: 100%;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-equipe {
    padding: 75px 5%;
    .image-side {
        width: 55%;
        background: url("../images/residentielle_section04_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    .text-side {
        width: 45%;
        margin: 0 15px 0 0;
        background: #E4E4E465;
        padding: 65px 45px 35px 45px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 25px;
        }
        p {
            color: #080808;
        }
        a.btn-link {
            background: #080808;
            border: 1px solid $color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 470px;
            height: 80px;
            border-radius: 5px;
            margin-top: 100px;
            transition: 0.3s;
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-1;
                letter-spacing: 1.8px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                h5 {
                    color: #080808;
                    transition: 0.3s;
                } 
            }
        }
    }
    @media screen and (max-width: 1360px) {
        .text-side {
            a.btn-link {
                width: 435px;
            }
        }
        @media screen and (max-width: 1230px) {
            .text-side {
                a.btn-link {
                    width: 385px;
                }
            }
            @media screen and (max-width: 1120px) {
                flex-direction: column-reverse;
                .image-side {
                    width: 80%;
                    margin: auto;
                }
                .text-side {
                    width: 80%;
                    margin: 15px auto 0 auto;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-side {
                            background: url("../images/residentielle_section04_img01_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                        .text-side {
                            padding: 60px 25px 35px 25px;
                            a.btn-link {
                                width: 315px;
                                margin-top: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* TOITURE COMMERCIALE
*******************************************************************************/

#section-intro {
    padding: 75px 5%;
    margin: -250px 5% 100px 5%;
    background: #100000;
    position: relative;
    .left-side {
        width: 55%;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #E4E4E4;
            padding-bottom: 15px;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: $color-1;
            text-transform: uppercase;
            line-height: 1.5;
            padding-bottom: 25px;
        }
        a.btn-link {
            background: #080808;
            border: 1px solid $color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 210px;
            height: 80px;
            border-radius: 5px;
            margin-top: 15px;
            transition: 0.3s;
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-1;
                letter-spacing: 1.8px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                h5 {
                    color: #080808;
                    transition: 0.3s;
                } 
            }
        }
    }
    .right-side {
        width: 45%;
        margin: 0 15px;
        
        p {
            color: #E4E4E4;
        }
        
    }
    @media screen and (max-width: 1100px) {
        flex-direction: column;
        margin-top: -110px;
        .left-side {
            width: 100%;
            margin-bottom: 35px;
        }
        .right-side {
            width: 100%;
        }
        @media screen and (max-width: $size-sm-max) {
            margin-top: -100px;
            @media screen and (max-width: $size-xs-max) {
                margin-top: -185px;
                .left-side h4 br {
                    display: none;
                }
            }
        }
    } 
}

/*******************************************************************************
* 布罗萨德 (BÙ LUÓ SÀ DÉ)
*******************************************************************************/

#section-intro-bu {
    padding: 75px 5%;
    margin: -125px 5% 35px 5%;
    background: #080808;
    position: relative;
    text-align: center;
    h2 {
        font-weight: 600;
        font-size: $font-size-50;
        color: #E4E4E4;
    }
    @media screen and (max-width: $size-md-max) {
        margin: 0 0 35px 0;
    }
}

#section-bu {
    padding: 75px 5%;
    .image-side {
        width: 55%;
        background: url("../images/bu_luo_section01_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    .text-side {
        width: 45%;
        margin: 0 15px 0 0;
        background: #E4E4E465;
        padding: 65px 45px 35px 45px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 25px;
        }
        p {
            color: #080808;
        }
        a.btn-link {
            background: #080808;
            border: 1px solid $color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 215px;
            height: 80px;
            border-radius: 5px;
            margin-top: 100px;
            transition: 0.3s;
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-1;
                letter-spacing: 1.8px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                h5 {
                    color: #080808;
                    transition: 0.3s;
                } 
            }
        }
    }
    &.second {
        padding: 0 5%;
        .image-side {
            background: url("../images/bu_luo_section01_img02.jpg") no-repeat;
            background-size: cover;
            background-position: center;
        }
        .text-side {
            margin: 0 0 0 15px;
        }
    }
    &.third {
        .image-side {
            background: url("../images/bu_luo_section01_img03.jpg") no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    @media screen and (max-width: 1240px) {
        .text-side p br {
            display: none;
        }
        @media screen and (max-width: 1120px) {
            flex-direction: column-reverse;
            .image-side {
                width: 80%;
                margin: auto;
            }
            .text-side {
                width: 80%;
                margin: 15px auto 0 auto;
            }
            &.second {
                flex-direction: column;
                .text-side {
                    margin: 15px auto 0 auto;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
                .text-side a.btn-link {
                    margin-top: 60px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .image-side {
                        background: url("../images/bu_luo_section01_img01_m.jpg") no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    .text-side {
                        padding: 60px 25px 35px 25px;
                        a.btn-link {
                            margin-top: 40px;
                        }
                    }
                    &.second {
                        .image-side {
                            background: url("../images/bu_luo_section01_img02_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }  
                    }
                    &.third {
                        .image-side {
                            background: url("../images/bu_luo_section01_img03_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
            }
        }   
    }
}

/*******************************************************************************
* COODONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    background: #E4E4E465;
    .form-info.flex {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 15px;
        background: $color-white;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #2D2D2D;
            letter-spacing: 1.2px;
            padding-bottom: 15px;
        }
        p {
            color: #080808;
            padding-bottom: 25px;
            @media screen and (max-width: 1490px) {
                br {
                    display: none;
                }
            }
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .section-info {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: #100000;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #E4E4E4;
            line-height: 1.3;
            text-transform: uppercase;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #E4E4E4;
            text-transform: uppercase;
            padding-bottom: 15px;
        }
        .section-tel {
            padding-top: 70px;
            h4 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
            }
        }
        .section-address {
            padding: 50px 0;
            h5 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
            }
        }
        .jours-heures {
            .jour {
                width: 43%;
            }
            h4 {    
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
                line-height: 1.8;
            }
            h5 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
                line-height: 1.8;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .form-info {
            flex-direction: column-reverse;
            margin-top: -150px !important;
            .section-info {
                width: 75%;
                margin: auto;
            }
            .section-form {
                width: 85%;
                margin: 35px auto 0 auto;
            }
        }
        @media screen and (max-width: 1024px) {
            .form-info {
                .section-info {
                    padding: 65px 45px;
                    .section-tel {
                        padding-top: 70px;
                    }
                    .section-address {
                        padding: 35px 0;
                    }
                }
                .section-form {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .form-info {
                    margin-top: -95px !important;
                    .section-info {
                        width: 90%;
                        .jours-heures .jour {
                            width: 32%;
                        }
                    }
                    .section-form {
                        padding: 65px 50px 50px 50px;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .form-info {
                        margin-top: -130px !important;
                        .section-info {
                            width: 100%;
                            padding: 50px 25px;
                            .jours-heures .jour {
                                width: 32%;
                            }
                            h2 {
                                text-align: center;
                            }
                        }
                        .section-form {
                            padding: 50px 25px;
                            .form-group.col-xs-12.col-sm-6.pl0 {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 75px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-44;
        color: #080808;
        padding-bottom: 65px;
    }
    @media screen and (max-width: $size-xs-max) {
        h3 {
            font-size: 2rem;
            text-align: center;
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: #080808;;
    }
}








/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
