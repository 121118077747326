.btn {
    background-color: $color-black;
    color: $color-1;
    border: 1px solid $color-1;
    border-radius: 6px;
    padding: 20px;
    font-weight: 600;
    font-size: $font-size-18;
    letter-spacing: 1.8px;
    width: 175px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-1;
        color: $color-black;
        border: 1px solid $color-1;
    }
}
