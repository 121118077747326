footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .text-color-grey;
        @extend .font-footer;

        .logo {
            width: 17%;
        }

        .copyright-mediaweb {
            width: 23%;
            .copyright {
                font-weight: bold;
                font-size: $font-size-16;
                color: #E4E4E4;
                margin-bottom: 15px;
            }
        }
        
        .bloc-info {
            width: 19%;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #E4E4E4;
                padding-bottom: 10px;
            }
            h5 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
            }
        }
        @media screen and (max-width: 1775px) {
            .logo {
                width: 17%;
            }
            .copyright-mediaweb {
                width: 22%;
            }
            @media screen and (max-width: 1600px) {
                .bloc-info h5 {
                    font-size: 0.9rem;
                }   
                @media screen and (max-width: 1500px) {
                    .logo {
                        width: 22%;
                    }
                    .copyright-mediaweb {
                        width: 34%;
                    }
                    .bloc-info {
                        width: 22%;
                    }
                    @media screen and (max-width: 1185px) {
                        .logo {
                            width: 100%;
                        }
                        .copyright-mediaweb {
                            width: 100%;
                            padding: 30px 0;
                        }
                        .bloc-info {
                            width: 100%;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1185px) {
        min-height: 615px;
        @media screen and (max-width: $size-xs-max) {
            min-height: 570px;
        }
    }
}
